import React, { lazy } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

const AddUser = lazy(() => import('../../container/pages/AddUsers'));

const PagesRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/edit-user/:id`} component={AddUser} />
    </Switch>
  );
};

export default PagesRoute;
