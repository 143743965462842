import React, { useState, useEffect } from 'react'
import { Menu } from 'antd';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import FeatherIcon from 'feather-icons-react';
import propTypes from 'prop-types';
import versions from '../demoData/changelog.json';
import { logOut } from '../redux/authentication/actionCreator';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();

  const pathName = window.location.pathname;
  const pathArray = pathName.split(path);
  const mainPath = pathArray[1];
  const mainPathSplit = mainPath.split('/');
  const [preLoading, setPreloading] = useState(true);
  const [groups, setGroups] = useState([]);

  const { onRtlChange, onLtrChange, modeChangeDark, modeChangeLight, modeChangeTopNav, modeChangeSideNav } = events;
  const [openKeys, setOpenKeys] = React.useState(
    !topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : [],
  );

  const { isLoggedIn, user_id, user_category } = useSelector(state => {
    return {
      user_category: state.auth.user_category,
      user_id: state.auth.user_id,
    };
  });
  const logoutOff = () => {
    dispatch(logOut());
  }
  const onOpenChange = keys => {
    setOpenKeys(keys[keys.length - 1] !== 'recharts' ? [keys.length && keys[keys.length - 1]] : keys);
  };

  const onClick = item => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };
  const UpdateMenu = async () => {

    // const relatorios = await axios.get('http://192.168.56.1:1111/api/v1/power/groups');
    //setGroups(relatorios.data.groups);
  }

  useEffect(() => {
    if (preLoading == true) {
      UpdateMenu();
      setPreloading(false);
    }
  });
  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? 'inline' : 'horizontal'}
      theme={darkMode && 'dark'}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
            `${mainPathSplit.length === 1 ? 'home' : mainPathSplit.length === 2 ? mainPathSplit[1] : mainPathSplit[2]
            }`,
          ]
          : []
      }
      defaultOpenKeys={!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : 'dashboard'}`] : []}
      overflowedIndicator={<FeatherIcon icon="more-vertical" />}
      openKeys={openKeys}
    >

      {(user_category != 'C') &&
        <Menu.Item key="appointments" icon={<FeatherIcon icon="list" />}>
          <NavLink onClick={toggleCollapsed} to={`${path}/appointments_list`}>
            Apontamento
          </NavLink>
        </Menu.Item>
      }

      {(user_category == 'F' || user_category == 'C' || user_category == 'D' || user_category == 'G') &&
        <Menu.Item key="aprovacao-os" icon={<FeatherIcon icon="bar-chart-2" />}>
          <NavLink onClick={toggleCollapsed} to={`${path}/aprovacao`}>
            Aprovação de O.S
          </NavLink>
        </Menu.Item>
      }

      {(user_category == 'D' || user_category == 'G') &&

        <Menu.Item key="performance-projeto" icon={<FeatherIcon icon="bar-chart-2" />}>
          <NavLink onClick={toggleCollapsed} to={`${path}/dashprojeto`}>
            Performance por projeto
          </NavLink>
        </Menu.Item>
      }


      {(user_category != 'C' && 1 == 0) &&
        <Menu.Item key="relatorio" icon={<FeatherIcon icon="edit-3" />}>
          <NavLink onClick={toggleCollapsed} to={`${path}/timereport`}>
            Relatório de horas
          </NavLink>
        </Menu.Item>
      }

      {(user_category == 'F' || user_category == 'D' || user_category == 'G') &&
        <Menu.Item key="fechamento" icon={<FeatherIcon icon="dollar-sign" />}>
          <NavLink onClick={toggleCollapsed} to={`${path}/fechamento`}>
            Fechamento cliente
          </NavLink>
        </Menu.Item>
      }

      {(user_category == 'F' || user_category == 'D' || user_category == 'G') &&
        <Menu.Item key="relatoro_analista" icon={<FeatherIcon icon="dollar-sign" />}>
          <NavLink onClick={toggleCollapsed} to={`${path}/timereport`}>
            Relatório analista
          </NavLink>
        </Menu.Item>
      }


      {(user_category == 'F' || user_category == 'C' || user_category == 'D' || user_category == 'G') &&

        <SubMenu key="financeiro" icon={!topMenu && <FeatherIcon icon="dollar-sign" />} title="Financeiro">
          <Menu.Item key="clientereport" >
            <NavLink onClick={toggleCollapsed} to={`${path}/clientreport`}>
              Relatório cliente
            </NavLink>
          </Menu.Item>
          <Menu.Item key="client_report" >
            <NavLink onClick={toggleCollapsed} to={`${path}/client_report`}>
              Apontamentos cliente
            </NavLink>
          </Menu.Item>
        </SubMenu>
      }


      {(user_category == 'G' || user_category == 'D') &&
        <SubMenu key="usuarios" icon={!topMenu && <FeatherIcon icon="user-plus" />} title="Cadastros">
          <Menu.Item key="grid">
            <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
              Usuários
            </NavLink>
          </Menu.Item>
          <Menu.Item key="clientes">
            <NavLink onClick={toggleCollapsed} to={`${path}/customers`}>
              Clientes
            </NavLink>
          </Menu.Item>
          <Menu.Item key="tipos_despesa">
            <NavLink onClick={toggleCollapsed} to={`${path}/expenses`}>
              Tipos despesa
            </NavLink>
          </Menu.Item>
        </SubMenu>

      }

      {(user_category == 'D' && user_category == 'Z') &&
        <Menu.Item key="periods" icon={<FeatherIcon icon="calendar" />}>
          <NavLink onClick={toggleCollapsed} to={`${path}/periods`}>
            Períodos sistema
          </NavLink>
        </Menu.Item>
      }
      {(user_category == 'D' && user_category == 'Z') &&
        <Menu.Item key="feriados" icon={<FeatherIcon icon="calendar" />}>
          <NavLink onClick={toggleCollapsed} to={`${path}/feriados`}>
            Feriados
          </NavLink>
        </Menu.Item>
      }
      {(user_category == 'D') &&
        <Menu.Item key="configuracao" onClick={toggleCollapsed} icon={<FeatherIcon icon="layers" />}>
          <NavLink onClick={toggleCollapsed} to={`${path}/system/config`}>
            Configurações
          </NavLink>
        </Menu.Item>
      }
      <Menu.Item key="profile" onClick={toggleCollapsed} icon={<FeatherIcon icon="user" />}>
        <NavLink onClick={toggleCollapsed} to={`${path}/users/edit-user/${user_id}/info`}>
          Meu perfil
        </NavLink>
      </Menu.Item>
      <Menu.Item key="logout" onClick={logoutOff} icon={<FeatherIcon icon="log-out" />}>
        &nbsp;&nbsp;&nbsp; Sair
      </Menu.Item>

    </Menu>
  );
};

MenuItems.propTypes = {
  darkMode: propTypes.bool,
  topMenu: propTypes.bool,
  toggleCollapsed: propTypes.func,
  events: propTypes.object,
};

export default MenuItems;
