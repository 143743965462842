import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader/root';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import Cookies from 'js-cookie';

import store from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import { logOut } from './redux/authentication/actionCreator';
import ProtectedRoute from './components/utilities/protectedRoute';

import axios from 'axios';
import Api from './api/User';
import ptBR from 'antd/lib/locale/pt_BR';
const { theme } = config;

const ProviderConfig = () => {
  const dispatch = useDispatch();
  const { rtl, isLoggedIn, topMenu, darkMode, token } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
      token: state.auth.token,
    };
  });
  const [path, setPath] = useState(window.location.pathname);
  const vToken     = useSelector(state => state.auth.token);

  if(vToken){
    axios.defaults.headers.common = {
        'Authorization': 'Bearer ' + vToken
    };
  }
  const fCheck = async() =>{
    try{
      
      let CST = await Api.checkToken();
      if(CST.data.code != 0){
        dispatch(logOut());
      }
    }catch(e){
      console.log("ES1",e);
    }
  }

  if(isLoggedIn){
    fCheck();
  }
  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);
  return (
    <ConfigProvider direction={rtl ? 'rtl' : 'ltr'} locale={ptBR}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? <Route path="/" component={Auth} /> : <ProtectedRoute path="/admin" component={Admin} />}
          {isLoggedIn && (path === process.env.PUBLIC_URL || path === `${process.env.PUBLIC_URL}/`) && (
              <Redirect to="/admin/appointments_list" />
          )}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {

  const params = new URLSearchParams(location.search);
  const redirectUrl = params.get('redirectUrl');

  if (redirectUrl) {
    Cookies.set('redirectUrl', redirectUrl);
  }

  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
